$before-color: #8045c6;
$after-color: #af43f1;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Popins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8f8;
}

.card {
  position: relative;
  width: 300px;
  height: 100vh;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
}

.card i {
  position: absolute;
  top: 40px;
  left: -15px;
  width: 280px;
  height: 50px;
  //   background-color: #af43f1;
  background-color: var(--before-color, $before-color);
  //   border-radius: 30px;
  border-top-left-radius: 30px;
  border-end-end-radius: 15px;
  border-bottom-left-radius: 0;
}

.card i::before {
  content: "";
  position: absolute;
  top: 50px;
  width: 15px;
  height: 30px;
  //   background: #8045c6;
  background-color: black;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 2;
}

.card i::after {
  content: "";
  position: absolute;
  top: 50px;
  width: 15px;
  height: 15px;
  //   background: #af43f1;
  background-color: var(--before-color, $before-color);
  z-index: 1;
}
.title {
  position: absolute;
  top: 50px;
  left: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}
.monthly {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantage {
  margin-left: 30px;
  margin-bottom: 10px;
}
.Nomonthly {
  margin-top: 170px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
}
.btn-custom {
  background: linear-gradient(to right, #874ccc, #c65bcf);
  color: white;
  border-radius: 25px;
}
