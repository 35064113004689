.term-condition {
  cursor: pointer;
  color: #428aca;
}
/* Popup modal styles */
.modal {
  display: none; /* Hide the modal by default */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  overflow: auto; /* Enable scrolling when modal content exceeds viewport */

  &.show {
    display: flex; /* Show the modal when the "show" class is applied */
  }

  .modal-content {
    background-color: #fff;
    width: 80%;
    max-width: 600px;
    height: auto;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #333 transparent;
    // padding: 20px;
    margin-top: 20vh;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    position: relative;

    h2 {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
      position: sticky;
      top: 0;
      padding: 20px;
      background-color: #fff; /* Adjust background color as needed */
      z-index: 1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .p-4 {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.5;
    }

    .btn_cs {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 12px;

      button {
        margin-left: 10px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:first-child {
          background-color: #ccc;
          color: #333;

          &:hover {
            background-color: #bfbfbf;
          }
        }

        &:last-child {
          background-color: #007bff;
          color: #fff;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }
}
