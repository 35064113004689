.tabs-container {
  width: 100%;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px;
}

.tabs button {
  background-color: transparent;
  border: none;
  padding: 20px 20px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  border-radius: 50px 2px;
  background-color: #f8f8f8;
  width: 100%;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }

  &.active {
    color: #007bff;
    font-weight: bold;
  }
}

.tab-content {
  padding: 20px;
}

/* Optional: Add additional styles as needed */
.container-privacy {
  padding-left: 100px;
  padding-right: 100px;
}
.container-title {
  color: black;
}

.custom-numbered-list {
  color: #428aca;
  font-size: 18px;
}

.ol-title {
  color: black;
}

.ul-styled {
  margin-left: 10px;
  padding-left: 20px;
  list-style: square outside none;
  color: black;
}

.custom-numbered-list-definition {
  color: black;
}

@media (max-width: 1700px) {
  .container-privacy {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (max-width: 991px) {
  .container-privacy {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .container-privacy {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 420px) {
  .container-privacy {
    padding-left: 0;
    padding-right: 0;
  }
}
